<template>
    <div id="player">
        <audio style="display:none" ref="audio" :id="playerid">
            <source :src="url" type="audio/mpeg" />
        </audio>

        <b-progress :max="audioDuration">      
            <b-progress-bar 
                ref="bar"
                :value="playbackTime" 
                :label="`${ elapsedTime() +' / '+ totalTime() }`"
                variant="success" 
                :striped="striped"
                :animated="animated">
            </b-progress-bar>
        </b-progress>
    </div>
</template>

<script>

export default {
    name: 'Audio',

    props: ["url", "playerid", "t"],

    /**
     * playbackTime = local var that syncs to audio.currentTime
     * audioDuration = duration of audio file in seconds
     * isPlaying = boolean (true if audio is playing)
     *
     **/

    data() {
        return {
            playbackTime: 0,
            audioDuration: 100,
            audioLoaded: false,
            isPlaying: false,
            file: null,
            striped: false,
            animated: false
        };
    },

    created() {
        window.pause = this.Pause;
        window.repeat = this.Repeat;
    },

    watch: {
        url: function() {
            if (this.url !== null) {
                let audio = this.$refs.audio;
                this.file = this.url.substring(this.url.lastIndexOf('/')+1);
 
                if (this.url !== null) {
                    audio.pause();
                    audio.load(); //suspends and restores all audio element
                    audio.play();
                    this.isPlaying = true;
                } else {
                    this.playbackTime = 0;
                }
            }
        },
        isPlaying: function () {
            if (this.isPlaying) {
                this.t.Status = this.t.Playing + '<a href="'+this.url+'" download="'+this.file+'" target="_blank">'+this.file+'</a>';
                this.striped = true;
                this.animated = true;
            } else {
                this.t.Status = this.t.StopAfterP + '<a href="'+this.url+'" download="'+this.file+'" target="_blank">'+this.file+'</a>';
                this.striped = false;
                this.animated = false;
            }
        }
    },

    mounted() {        
        
        this.$nextTick(function() {
        
            var audio=this.$refs.audio;
            
            //Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider 
            // "loademetadata" Event https://www.w3schools.com/tags/av_event_loadedmetadata.asp
            audio.addEventListener("loadedmetadata", function() {
                this.initSlider();
            }.bind(this));

            // "canplay" HTML Event lets us know audio is ready for play https://www.w3schools.com/tags/av_event_canplay.asp
            audio.addEventListener("canplay", function() {
                this.audioLoaded=true;
            }.bind(this));

            //Wait for audio to begin play, then start playback listener function
            this.$watch("isPlaying",function() {

                if(this.isPlaying) {
                    var audio=this.$refs.audio;
                    this.initSlider();
                    //console.log("Audio playback started.");

                    //prevent starting multiple listeners at the same time
                    if(!this.listenerActive) {
                        this.listenerActive=true;

                        //for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
                        audio.addEventListener("timeupdate",this.playbackListener);
                    }
                }
            });

            //Update current audio position when user drags progress slider
            this.$watch("playbackTime",function() {
                var audio=this.$refs.audio;
                var diff=Math.abs(this.playbackTime - audio.currentTime);
        
                //Throttle synchronization to prevent infinite loop between playback listener and this watcher
                if(diff>0.01) {
                    audio.currentTime=this.playbackTime;
                }
            });
        });
    },

    methods: {
        Pause () {
            var audio = this.$refs.audio;
            if (!this.isPlaying) {
                audio.play();
                this.isPlaying = true;
            } else {
                audio.pause();
                this.isPlaying = false;
            }
        },

        Repeat () {
            var audio = this.$refs.audio;
            if (!audio.paused) {
                audio.pause();
                this.isPlaying = false;
            }
            audio.currentTime = this.playbackTime = 0;
            this.initSlider();
            audio.play();
            this.isPlaying=true;
        },

        //Set the range slider max value equal to audio duration
        initSlider() {
            var audio = this.$refs.audio;
            if (audio) {
                this.audioDuration = Math.round(audio.duration);
            }
        },

        //Convert audio current time from seconds to min:sec display
        convertTime(seconds){
            const format = val => `0${Math.floor(val)}`.slice(-2);
            //var hours = seconds / 3600;
            var minutes = (seconds % 3600) / 60;

            return [minutes, seconds % 60].map(format).join(":");

        },

        //Show the total duration of audio file
        totalTime() {
            var audio = this.$refs.audio;
            if (audio) {
                var seconds = audio.duration;
                return this.convertTime(seconds);

            } else {
                return '00:00';
            }
        },

        //Display the audio time elapsed so far
        elapsedTime() {
            var audio = this.$refs.audio;
            if (audio) {
                var seconds = audio.currentTime;
                return this.convertTime(seconds);

            } else {
                return '00:00';
            }
        },

        //Playback listener function runs every 100ms while audio is playing
        playbackListener() {

            var audio = this.$refs.audio;

            //Sync local 'playbackTime' var to audio.currentTime and update global state
            this.playbackTime = audio.currentTime;
            
            //console.log("update: " + audio.currentTime);

            //Add listeners for audio pause and audio end events
            audio.addEventListener("ended", this.endListener);
            audio.addEventListener("pause", this.pauseListener);
        },

        //Function to run when audio is paused by user
        pauseListener() {
            this.isPlaying = false;
            this.listenerActive = false;
            this.cleanupListeners();
        },
        //Function to run when audio play reaches the end of file
        endListener() {
            this.isPlaying = false;
            this.listenerActive = false;
            this.cleanupListeners();
        },
        //Remove listeners after audio play stops
        cleanupListeners() {
            var audio = this.$refs.audio;
            audio.removeEventListener("timeupdate", this.playbackListener);
            audio.removeEventListener("ended", this.endListener);
            audio.removeEventListener("pause", this.pauseListener);

            //console.log("All cleaned up!");
        }
    }
}
</script>

<style>
.progress,
.progress-bar {
    border-radius: 15px;
    background-color: black;
}
</style>