<template>
    <b-sidebar id="sidebar-left" :title="t.History" left backdrop shadow>
        <div class="px-3 py-2">
            <b-row class="box py-4">
                <b-col>
                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1" v-for="item in histories" :key="item.id">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-' + item.id" variant="synt">{{item.text}}
                                </b-button>
                            </b-card-header>
                            <b-collapse :id="'accordion-'+item.id" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col class="ctext">
                                            <b-textarea plaintext rows="5" autofocus :value="item.text"> </b-textarea>
                                        </b-col>
                                            <b-col class="ctrlbtn">
                                                <b-button pill variant="synt" @click="play(item.url)" :aria-label="t.Speak">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" x="0px"
                                                        y="0px" viewBox="0 0 20 20">
                                                        <polygon class="st0" points="1.4,0.2 18.4,10 1.4,19.8 " />
                                                    </svg>
                                                    <span class="label"> {{ t.Speak }}</span>
                                                </b-button>
                                            </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <span style="display: block; text-align:right">{{t.historyTime}}:
                                                <strong>{{item.time}}</strong>
                                            </span>
                                        </b-col>
                                        <b-col>
                                            <span style="display: block; text-align:right">{{t.historyFile}}:
                                                <strong>
                                                    <a :href="item.url" download="">{{getFileName(item.url)}}</a>
                                                </strong>
                                            </span>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-sidebar>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
    name: 'History',

    data() {
        return {
            histories: null,
            histFN: null
        }
    },

    created() {
        window.loadHist = this.loadHistory;
    },

    props: [ "con_id", "t", "lang" ],

    mounted() {
        this.loadHistory();
    },

    methods: {

        loadHistory: function() {
            var url = './php/history.php';

            const params = {
                con_id: this.con_id,
                lang: this.lang,
            };
            axios
            .post(url, qs.stringify(params))
            .then((response) => {
                this.histories = response.data;
//                console.log(this.histories);
            })
            .catch((error) => {
                console.log(error);
            });

        },

        getFileName: function(url) {
            return url.substring(url.lastIndexOf('/') + 1);
        },

        play: function(fn) {
            this.$emit('fname', fn);
        }

    }

}
</script>

<style scoped>


.btn-synt {
    display: block;
    width: calc(100%); 
    text-overflow: "..." !important;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 5px;
}
.card {
    border: none;
}
.card-header {
    border-bottom: none;
}
.card-body {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    background-color: rgba(0, 0, 0, .03);
}
.ctext {
    background-color: rgb(193, 237, 255);
}
</style>