<template>
    <b-overlay :show="show" style="block: fixed; width: 100%; max-height: 100%">

        <b-container>
        
        <b-navbar class="synt-nav" fixed="top">
            <b-navbar-nav style="width:100%" justified>
                <b-navbar-brand>
                    <b-link href="//www.mella.ee" target="_blank">
                        <img src="../assets/Mella.png" class="rounded-circle" alt="Mella">
                    </b-link>
                </b-navbar-brand>
                <b-nav-text class="navtext mx-auto">
                    <h1 id="AppName">{{ t.AppName }}</h1>
                </b-nav-text>
                <b-button id="setbtn" class="rounded-circle" variant="synt" v-b-toggle.sidebar-right
                    :title="t.Settings">
                    <b-icon icon="gear-fill" animation="spin" font-scale="1.5" aria-hidden="true"></b-icon>
                </b-button>
            </b-navbar-nav>
        </b-navbar>

        <b-row>
            <b-col id="mainWin">
                <b-row class="inputbox">
                    <b-col>
                        <label for="syntText" class="sr-only">{{ t.Input }}</label>
                        <b-form-textarea id="syntText" v-model="text" ref="tbox" @keydown="keyDown" @keyup="keyUp" size="lg"
                            :placeholder="t.enterText" :style="{ 'font-size': fontSize + '%', 'height': textareaHeight+'px' }" autofocus>
                        </b-form-textarea>
   
                        <div id="status">
                            <b-col>
                                <span v-html="t.Status"></span>
                            </b-col>
                            <b-col>
                                <Audio :url="url" :t="t" playerid="audio-player" />
                            </b-col>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="buttons">
                    <b-col class="btnnext" v-for="aword in awords" :key="aword.word">
                        <b-button pill variant="synt" class="autoword" :title="aword.word + ' - ' + aword.score"
                            v-on:click="autocomplete(aword.word)" :aria-label="aword.word">
                            {{ aword.word }}
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="buttons">
                    <b-col class="ctrlbtn">
                        <b-button pill variant="synt" @click="Speak" :aria-label="t.Speak">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" x="0px"
                                y="0px" viewBox="0 0 20 20">
                                <polygon class="st0" points="1.4,0.2 18.4,10 1.4,19.8 " />
                            </svg>
                            <span class="label"> {{ t.Speak }}</span>
                        </b-button>
                    </b-col>
                    <b-col class="ctrlbtn">
                        <b-button pill variant="synt" @click="Pause" :aria-label="t.Pause">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" x="0px"
                                y="0px" viewBox="0 0 20 20">
                                <rect x="0.5" y="0.5" class="st1" width="7.2" height="19" />
                                <rect x="12.3" y="0.5" class="st1" width="7.2" height="19" />
                            </svg>
                            <span class="label"> {{ t.Pause }}</span>
                        </b-button>
                    </b-col>
                    <b-col class="ctrlbtn">
                        <b-button pill variant="synt" @click="Rewind" :aria-label="t.Rewind">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" x="0px"
                                y="0px" viewBox="0 0 20 20">
                                <path class="st2"
                                    d="M10,0.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S15.2,0.5,10,0.5z M10,16.1 c-3.4,0-6.1-2.7-6.1-6.1S6.6,3.9,10,3.9s6.1,2.7,6.1,6.1S13.4,16.1,10,16.1z" />
                            </svg>
                            <span class="label"> {{ t.Rewind }}</span>
                        </b-button>
                    </b-col>
                    <b-col class="ctrlbtn">
                        <b-button pill variant="synt" @click="Empty" :aria-label="t.Empty">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" x="0px"
                                y="0px" viewBox="0 0 20 20">
                                <polygon class="st3"
                                    points="19.5,3.8 16.3,0.6 10,6.9 3.7,0.6 0.5,3.8 6.8,10.1 0.5,16.4 3.7,19.6 10,13.3 16.3,19.6 19.5,16.4 13.2,10.1 " />
                            </svg>
                            <span class="label"> {{ t.Empty }}</span>
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="buttons">
                    <div class="btnnext" v-for="speak in speakbuttons" :key="speak.name">
                        <b-button pill variant="synt" class="speakbutton" v-on:click="Say(speak.speech_pattern)"
                            :title="speak.name" :aria-label="speak.name">
                            {{ speak.name }}
                        </b-button>
                    </div>

                    <div class="btnnext">
                        <b-button pill variant="synt" v-b-toggle.sidebar-left :title="t.History">{{ t.History }}</b-button>
                    </div>
                </b-row>
            </b-col>
        </b-row>

        <b-alert v-model="debug" variant="info" dismissible>
            <h4>Debug info: </h4>
            <dic v-if="errorSynt">Error at Synthesizing: {{errorSynt}}</dic>
            <div v-if="text">Text:<div style="padding: 5px; border: 1px solid black; white-space: pre-wrap;">{{ text }}</div></div>
            <div v-if="lastWord">lastWord = {{ lastWord }}</div>       
            <div v-if="curKey">curKey = [{{ curKey }}]</div>
            <div v-html="debugmessage"></div>
            <div v-if="con_id">Connection ID: {{ con_id }}</div>
            <div v-if="Width">Window Width is {{ Width }}</div>
            <div v-if="errorStr">Sorry, but the following error occurred: {{ errorStr }}</div>
            <div v-if="gettingLocation"><i>Getting your location...</i></div>
            <div v-if="location">Your location data is 
                <a :href="'https://www.google.ee/maps/place/' + location.coords.latitude + ',' + location.coords.longitude"
                    target="_blank">{{ location.coords.latitude }}, {{ location.coords.longitude }}</a>
            </div>
            <div v-if="url">Current sound file is <a :href="url">{{ url }}</a></div>
        </b-alert>        
        
        <footer class="footer fixed-bottom">
            <div class="text-center">
                <p>Copyright &copy; 2023 &middot; All Rights Reserved &middot; <a href="https://www.mella.ee" target="_blank">Meelis Luks</a></p>
            </div>
        </footer>

        <b-sidebar id="sidebar-right" :title="t.Settings" right backdrop shadow>
            <div class="px-3 py-2">

                <b-row class="box py-4">
                    <b-col>

                        <b-button block v-on:click="UpLang" role="button">
                            <span class="flang">{{ langbutton.name }}</span>
                        </b-button>

                        <b-form-group class="settingFrame" :label="t.voices">
                            <b-form-select v-model="Voice" :options="voices"></b-form-select>
                        </b-form-group>

                        <label class="spinlabel" for="fontSize">{{ t.fontSize }}</label>
                        <b-form-spinbutton id="fontSize" v-model="fontSize" min="90" max="490" step="10">
                        </b-form-spinbutton>

                        <label class="spinlabel" for="Volume">{{ t.Volume }}</label>
                        <b-form-spinbutton id="Volume" v-model="Volume" min="-5" max="15" step="0.5">
                        </b-form-spinbutton>

                        <label class="spinlabel" for="speechSpeed">{{ t.speechSpeedRate }}</label>
                        <b-form-spinbutton id="speechSpeed" v-model="SpeedRate" min="0.1" max="2.76" step="0.01">
                        </b-form-spinbutton>

                        <label class="spinlabel" for="Pitch">{{ t.Pitch }}</label>
                        <b-form-spinbutton id="Pitch" v-model="Pitch" min="25000" max="85000" step="1000">
                        </b-form-spinbutton>

                        <label class="spinlabel" for="Alpha">{{ t.Alpha }}</label>
                        <b-form-spinbutton id="Alpha" v-model="Alpha" min="-0.8" max="0.8" step="0.05">
                        </b-form-spinbutton>

                        <b-button id="reSet" block v-on:click="reSet" role="button"> {{ t.reSet }} </b-button>

                    </b-col>
                </b-row>

            </div>
        </b-sidebar>

        <History @fname="playHistory" :con_id="con_id" :t="t" />
        
        </b-container>
    </b-overlay>
</template>

<script>
import Audio from '@/components/Audio.vue';
import History from '@/components/History.vue';
import axios from "axios";
import qs from "qs";
import welcome from "@/assets/Tervitus.mp3";

export default {
    name: "Synthesis",
    components: {
        Audio,
        History
    },
    data() {
        return {
            con_id: '',
            rndcode: '',
            langbutton: {
                flag: 'gb',
                name: 'English'
            },
            text: '',
            show: false,
            awords: null,
            speakbuttons: null,
            fontSize: 150,
            lastWord: '',
            curKey: '',
            gettingLocation: false,
            location: null,
            latitude: null,
            longitude: null,
            errorSynt: null,
            errorStr: null,
            debug: true,
            debugmessage: '',
            Width: window.innerWidth, 
            textareaHeight: window.innerHeight / 4 ^ 0,
            Voice: null,
            voices: [],
            Volume: 5.0,
            SpeedRate: 0.8,
            Pitch: 48000,
            Alpha: 0.55,
            url: welcome,
        }
    },

    compatConfig: { MODE: 3 },

    computed: {  },
    
    created() {

        window.playHistory = this.playHistory;

        // do we support geolocation
        if (!("geolocation" in navigator)) {
            this.errorStr = 'Geolocation is not available.';
            return;
        }

        this.gettingLocation = true;
        // get position
        navigator.geolocation.getCurrentPosition(pos => {
            this.gettingLocation = false;
            this.location = pos;
            this.latitude = this.location.coords.latitude;
            this.longitude = this.location.coords.longitude;
        }, err => {
            this.gettingLocation = false;
            this.errorStr = err.message;
        })

        if (localStorage.con_id) {
            this.con_id = localStorage.con_id;
        } else {
            var length = 32;
            this.con_id = this.rndCode(length, 'aA#');
            localStorage.con_id = this.con_id;
        }

    },
    mounted() {

        this.autowordlist(this.lastWord);
        this.wordbuttonslist();
        this.voiceList();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });

    },
    watch: {

        lang() {
            this.langbutton = (this.lang === 'et') ? { flag: 'gb', name: 'English' } : { flag: 'ee', name: 'Eesti' };
            this.wordbuttonslist();
            this.autowordlist(this.lastWord);
            this.voiceList();
        }

    },
    props: ["t", "lang"],

    methods: {

        playHistory: function(fname) {
            this.url = fname;
        },

        keyDown: function (e) {
            console.log("KeyDown: " + e.key);
        
            let textbox = e.target;
                
            this.curKey = e.key;

            this.upFirst(textbox);

            // If current key was Enter then sent text to synthesis.
            if (this.curKey === 'Enter') {
                e.preventDefault();

                this.sendSpeak(this.text, true);
                this.url = null;
                return;
            }

        },

        keyUp: function (e) {
            //console.log("KeyUp: " + e.key);

            var textbox = e.target,
                caret = this.getCaretPosition(textbox);

            if (['.', ',', ';', ':', '?', '!'].includes(this.curKey)) {
                this.text = textbox.value.substring(0, caret.end) + ' ' + textbox.value.substring(caret.end, textbox.value.length);
                
                setTimeout(() => {
                    textbox.selectionStart = textbox.selectionEnd = caret.end + 1;
                }, 5);
                
                this.lastWord = '';
                this.autowordlist(this.lastWord);
            }

            this.lastWord = /\S+$/.exec(textbox.value.slice(0, caret.end)) ? /\S+$/.exec(textbox.value.slice(0, caret.end))[0] : '';
            this.autowordlist(this.lastWord);
            this.t.Status = (this.lastWord.length > 0) ? this.t.typedWord + this.lastWord : '';

        },

        sayCurKey: function (char) {
            var doC = 'abcdefghijklmnopqrsštuvwxyzžõäöü',
                ch = char.toLowerCase().trim();

            if (doC.indexOf(ch) !== -1) {
                this.sendSpeak(char, false);
            }

            console.log("SayKey: " + ch);
        },

        Pause() {
            window.pause();
        },

        Rewind() {
            window.repeat();
        },

        Empty: function () {
            var textbox = this.$refs.tbox;
            this.text = '';
            this.caseShift = true;
            this.lastWord = '';
            this.autowordlist(this.lastWord);
            this.url = null;
            this.t.Status = this.t.EmptyText;
            textbox.focus();
        },

        UpLang() {
            this.$emit('changeLang');
        },

        reSet() {
            this.Volume = 5.0;
            this.SpeedRate = 0.8;
            this.Pitch = 48000;
            this.Alpha = 0.55;
        },

        onResize() {
            this.textareaHeight = window.innerHeight / 4 ^ 0;
            this.autowordlist(this.lastWord);
        },

        Speak: function () {
            let txt = this.text;

            if (txt.length > 0) {
                this.url = null;
                this.sendSpeak(txt, true);
            } else {
                this.t.Status = this.t.EmptyInput;
            }
        },

        Say: function (word) {
            this.url = null;
            this.sendSpeak(word, false);
        },

        sendSpeak: function (txt, save) {

            if (txt.length > 0) {
                let url = './php/syntees.php';

                this.show = true;
                this.url = null;
                const params = {
                    text: txt,
                    save: save,
                    voice: this.Voice,
                    speed: this.SpeedRate,
                    sample: this.Pitch,
                    alpha: this.Alpha,
                    volume: this.Volume,
                    lang: this.lang,
                    con_id: this.con_id,
                    referrer: document.referrer,
                    latitude: this.latitude,
                    longitude: this.longitude
                };
                axios
                    .post(url, qs.stringify(params))
                    .then((response) => {
                        this.url = response.data.url;
                        this.errorSynt = response.data.error;
                        this.debugmessage = response.data.debugmessage;
                        this.show = false;

                        if (save) {
                            window.loadHist();
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

        },

        // Get language list
        voiceList: function () {
            var url = './php/voices.php';
            axios
                .get(url)
                .then((response) => {
                    this.Voice = this.t.Voice;
                    this.voices = response.data[this.lang];

                    // Default voice file for both language
                    this.Voice = (this.lang === 'et') ? this.voices[12].value : this.voices[2].value;

                })
                .catch((error) => {
                    console.log(error);
                });

        },

        autowordlist(w) {
            var url = './php/wordlist.php';
            var bnum = 6;

            this.Width = window.innerWidth;

            if (this.Width < 900) bnum = 4;

            const params = {
                word: w,
                lang: this.lang,
                limit: bnum
            };
            axios
                .post(url, qs.stringify(params))
                .then((response) => {
                    this.awords = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        wordbuttonslist() {
            var url = './php/getbuttons.php';

            const params = {
                lang: this.lang,
                synt_it: 1
            };
            axios
                .post(url, qs.stringify(params))
                .then((response) => {
                    this.speakbuttons = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        autocomplete: function (word) {
            
            if (word.length > 0) this.insertWord(word);
            
        },

        upFirst: function(textbox, insert=false) {
            let caret = this.getCaretPosition(textbox);

            // Change first letter of sentence's word.
            if ([' ', '.', ',', '?', '!'].includes(this.curKey) || insert) {
                let len = this.lastWord.length,
                    preStr = (textbox.value.length > len) ? textbox.value.substring(0, caret.end - len).trim() : '',
                    word;

                if ((preStr.length < 1 && this.lastWord.length > 0) || (['.', '?', '!'].includes(preStr[preStr.length - 1]))) {
                    word = this.alphabet(this.lastWord[0]) + this.lastWord.slice(1);
                    if (word !== 'undefined') {
                        this.text = textbox.value.substring(0, caret.end - len) + word + textbox.value.substring(caret.end, textbox.value.length);

                        setTimeout(() => {
                            textbox.selectionStart = textbox.selectionEnd = caret.end + 1;
                        }, 5);

                        this.lastWord = '';
                        this.autowordlist(this.lastWord);
                    }
                }
            }
        },

        insertWord: function (insert) {
            if (0 === insert) { return; }

            let textbox = this.$refs.tbox,
                caret = this.getCaretPosition(textbox),
                cursorPos = caret.start,
                tmpStr = textbox.value,
                preStr = (textbox.value.length > this.lastWord.length) ? textbox.value.substring(0, caret.end - this.lastWord.length).trim() : '';

            if (this.lastWord.length > 0) {
                caret.start -= this.lastWord.length;
                if (this.lastWord[0] === this.lastWord[0].toUpperCase()) {
                    insert = insert.charAt(0).toUpperCase() + insert.slice(1);
                }
                if (this.lastWord === this.lastWord.toUpperCase()) {
                    insert = insert.toUpperCase();
                }
            }

            if ((preStr.length < 1 && insert.length > 0) || (['.', '?', '!'].includes(preStr[preStr.length - 1]))) {
                insert = this.alphabet(insert[0]) + insert.slice(1);
            }
            
            this.text = tmpStr.substring(0, caret.start) + insert + tmpStr.substring(caret.end, tmpStr.length);

            setTimeout(() => {
                cursorPos += insert.length - this.lastWord.length;
                textbox.selectionStart = textbox.selectionEnd = cursorPos;
            }, 10);

            this.lastWord = '';
            this.autowordlist(this.lastWord);

            textbox.focus();
        },

        alphabet: function (char, toUp = true) {
            var doC = 'abcdefghijklmnopqrsštuvwxyzžõäöü',
                upC = 'ABCDEFGHIJKLMNOPQRSŠTUVWXYZŽÕÄÖÜ',
                all = upC + doC;

            if (toUp)
                if (doC.indexOf(char) !== undefined)
                    if (upC[doC.indexOf(char)] !== undefined)
                        return upC[doC.indexOf(char)];
                    else
                        return char;

            if (all.indexOf(char) !== -1)
                return true;
            else
                return false;
        },

        getCaretPosition: function (ctrl) {
            var start, end;
            if (ctrl.setSelectionRange) {
                start = ctrl.selectionStart;
                end = ctrl.selectionEnd;
            } else if (document.selection && document.selection.createRange) {
                var range = document.selection.createRange();
                start = 0 - range.duplicate().moveStart('character', -100000);
                end = start + range.text.length;
            }
            return {
                start: start,
                end: end
            }
        },

        rndCode: function (length, chars) {
            var mask = '';
            if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
            if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            if (chars.indexOf('#') > -1) mask += '0123456789';
            if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
            var result = '';
            for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
            return result;
        }

    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600&display=swap");

@-webkit-keyframes active {
    from {
        box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 10px 0px rgba(0, 0, 250, 0.6);
    }

    to {
        box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 3px #CECFD1;
    }
}

@keyframes active {
    from {
        box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 10px 0px rgba(0, 0, 250, 0.6);
    }

    to {
        box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 3px #CECFD1;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

body {
    background: #f8f8f8;
    min-height: 100vh;
    display: flex;
}

#AppName {
    font-size: 20pt;
}

.synt-nav {
    width: 100%;
    height: 60px !important;
}

.navbar-brand img {
    position: fixed;
    height: 50px;
    border: 1px solid black;
    top: 5px;
    box-shadow: 0 2px 3px 1px #FCFCFC;
    transition: all 0.2s ease;
}

.navtext h3 {
    line-height: 0px !important;
}

#setbtn {
    position: fixed;
    height: 44px;
    width: 44px;
    top: 7px;
    right: 15px;
}

#mainWin {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #8F9092;
    outline: none;
    box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 0 #CECFD1;
    transition: all 0.2s ease;
    text-shadow: 0 1px #fff;
    padding: 25px 15px 25px 15px;
    margin: 50px 0px 20px 0px;
    border-radius: 30px;
}

#syntText {
    width: 100%;
    border: none;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 120%;
    font-weight: 600;
    color: #606060;
    border-radius: 15px;
    overflow: auto;
    resize: none;
}

#status {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    outline: none;
    border: 1px solid #8F9092;
    box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 0 #CECFD1;
    transition: all 0.2s ease;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 100%;
    font-weight: 600;
    background-color: black;
    color: #00DD0B;
    width: 100%;
    border-radius: 30px;
    height: 30px;
    margin-bottom: 15px;
    margin-top: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: none;
}

button,
[role=button],
.btn-synt {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    height: 50px;
    background-image: linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%);
    border-radius: 30px;
    border: 1px solid #8F9092;
    box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 0 #CECFD1;
    transition: all 0.2s ease;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #0c0b0b;
    text-shadow: 0 1px #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "...";
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
}

button::-moz-focus-inner,
[role=button]::-moz-focus-inner {
    border: 0;
}

button>*,
[role=button]>*,
.btn-synt>* {
    transition: transform 0.2s ease;
}

button:hover:not([disabled]),
[role=button]:hover:not([disabled]) {
    box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 3px #CECFD1;
}

button:hover:not([disabled])>*,
[role=button]:hover:not([disabled])>* {
    transform: scale(0.975);
}

button:focus:not(:active),
[role=button]:focus:not(:active),
.btn-synt:focus:not(:active) {
    -webkit-animation: active 0.9s alternate infinite;
    animation: active 0.9s alternate infinite;
    outline: none;
}

button:active:not([disabled]),
[role=button]:active:not([disabled]) {
    box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
}

button:active:not([disabled])>*,
[role=button]:active:not([disabled])>* {
    transform: scale(0.95);
}

button:disabled,
[role=button]:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

button.icon,
[role=button].icon {
    width: 10px;
}

button.icon svg,
[role=button].icon svg {
    margin-top: 5px;
    width: 20px;
    height: 20px;
}

.btn-synt.active {
    background-image: linear-gradient(to top, #6171fd 0%, #fff 80%, #FDFDFD 100%);

}

nav,
footer {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    height: 40px;
    background-image: linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%);
    border: 1px solid #8F9092;
    -webkit-box-shadow: 0 6px 8px #D6D7D9, inset 0 0 3px 0 #CECFD1;
    box-shadow: 0 6px 8px #D6D7D9, inset 0 0 3px 0 #CECFD1;
    transition: all 0.2s ease;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #606060;
    text-shadow: 0 1px #fff;
}

#sidebar-right {
    max-width: 100%;
    width: 500px;
}

#sidebar-left {
    width: 100%;
}

button.close {
    margin-top: 5px;
    height: 35px;
    width: 35px;
}

.box {
    box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 0 #CECFD1;
    transition: all 0.2s ease;
    border: 1px solid #8F9092;
    text-shadow: 0 1px #fff;
    padding: 10px;
    border-radius: 15px;
    margin: 10px 0px 10px 0px;
}

.settingFrame {
    margin-top: 20px;
}

.settingFrame legend {
    font-weight: 600;
}

span.flang {
    padding-left: 20px;
}

.spinlabel {
    margin-top: 20px;
    font-weight: 600;
}

.b-form-spinbutton button {
    height: 40px;
    border-radius: 20px;
}

#reSet {
    margin-top: 25px;
}

.inputbox {
    display: block;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
}

.buttons {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
}

.buttons .ctrlbtn {
    display: block;
    width: 25%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
}

.buttons .btnnext {
    display: block;
    width: 16.66%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
}

.buttons .autoword {
    display: block;
    width: 16.66%;
    height: 35px;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    background-image: linear-gradient(to top, #000000 0%, rgb(143, 143, 143) 80%, #FDFDFD 100%);
}

.btnnext .btn-synt,
.ctrlbtn .btn-synt,
.autoword .btn-synt {
    display: block;
    width: 100%;
}

.ctrlbtn .btn-synt {
    font-size: 110%;
}

.speakbutton {
    background-image: linear-gradient(to top, #ffb1b1 0%, #fff 80%, #FDFDFD 100%);
}

.st0 {
    fill: #00FF00;
    stroke: #00A000;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

.st1 {
    fill: #00FFFF;
    stroke: #0083FF;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

.st2 {
    fill: #F9BC43;
    stroke: #C66300;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

.st3 {
    fill: #FF0000;
    stroke: #840000;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

@media screen and (max-width: 900px) {

    .buttons .btnnext {
        width: 25%;
    }

    .buttons .ctrlbtn .label {
        display: none;
    }

}
</style>