<template>
  <Synthesis :t="t" :lang="lang" v-on:changeLang="clang()" />
</template>

<script>
import language from './locales/langs.json';
import Synthesis from './components/Synthesis.vue';

export default {
    data() {
        return {
            t: [],
            lang: 'et'    
        }
    },
    
    compatConfig: { MODE: 3 },

    components: {
        Synthesis,
    },

    watch: {
        lang: function() {
            this.t = language[this.lang];
        }
    },

    mounted() {
        this.t = language[this.lang];
    },

    methods: {
        clang: function() {
            this.lang = (this.lang === 'et') ? 'en' : 'et';
        }
    }

};
</script>

<style>

</style>